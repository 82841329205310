/* You can add global styles to this file, and also import other style files */
@use "sass:map"
@use '@angular/material' as mat

@use './dataculture'
//@import 'ngx-sharebuttons/themes/circles'

@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel'
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default'

.app-button
  margin: 4px !important
  min-width: 100px !important

.flex-content
  display: flex
  flex-wrap: wrap
  justify-items: flex-start
  justify-content: space-around
  align-items: start

.fake-button
  @extend .app-button
  width: 80px
  height: 26px
  text-align: center
  display: inline-block
  cursor: pointer
  line-height: 90%
  font-size: small
  padding: 10px
  border: black solid 2px


.cdk-visually-hidden
  display: none

.app-card
  min-width: 300px
  margin-left: 0
  margin-right: 10px
  display: inline-block
  background: none !important

.div-center
  width: 100%
  text-align: center

.icon-button
  width: 22px
  font-size: 1em
  height: 22px
  margin: 3px !important
  display: inline-block
  cursor: pointer

.small-icon-button
  margin: 0 !important
  padding: 0
  display: inline-block
  cursor: pointer


.large-card
  min-width: 500px
  margin: 10px

.icon-profil
  width: 60px
  clip-path: circle()

  //https://css-tricks.com/snippets/css/a-guide-to-flexbox/
.wrap-item
  margin: 5px
  max-width: 500px
  min-width: 250px
  width: 80%

.wrap-align
  display: flex
  width: 100%
  flex-wrap: wrap
  justify-items: center

.wrap-list
  display: flex
  gap: 5px
  flex-wrap: wrap
  flex-direction: row
  justify-content: center
  align-content: flex-start
  justify-items: center
  align-items: flex-start

::-webkit-scrollbar
  width: 0
  background: transparent

::-webkit-scrollbar-thumb
  background: transparent


//Le container contient le side-menu et le sidenavcontent
.toolbar-app
  @extend .grey800
  height: 5vh


.container
  width: 100vw
  height: 95vh



.sidenav-content
  width: 100%
  overflow: hidden
  justify-content: center
  text-align: center
  border: none

.bodybg
  @extend .grey900
  position: fixed
  width: 100vw
  height: 100vh

.mainform
  width: 100%
  text-align: center !important
  background: none
  padding: 10px

.titleform
  margin: 10px
  @extend .mat-headline-6

mat-drawer-container
  background: none !important


.sidenav
  padding: 0


.app-field
  width: 95%
  max-width: 600px
  margin: 3px
  height: min-content


.bottom-bar
  width: 100%
  z-index: 100
  padding-top: 5px
  padding-right: 5px
  text-align: right
  position: fixed
  display: block
  bottom: 0
  right: 0
  height: 60px


.h-zone
  margin: 5px
  width: 100%
  text-align: center
  margin-bottom: 10px


.fill-remaining-space
  flex: 1 1 auto


.faq-content
  padding-left: 5px
  font-size: small
  text-align: left
  line-height: 110%


.bloc-bouton
  margin: 0
  padding: 0
  text-align: center
  display: inline-block
  line-height: 96%


mat-dialog-title
  font-style: normal
  font-weight: lighter
  font-size: medium


.app-card-panel
  max-width: 500px
  width: 40%
  padding: 10px
  min-width: 320px
  font-size: small
  display: inline-block



mat-icon
  @extend .color-grey600

a
  text-decoration: none
  cursor: pointer

a:link
  color: gray

a:visited
  color: gray

a:hover
  color: lightgray

a:active
  color: gray



.app-mat-panel-title
  text-align: left
  font-weight: bold
  font-size: small


.app-mat-panel-description
  font-weight: lighter
  text-align: left
  font-size: small
  vertical-align: center


mat-expansion-panel
  @extend .grey800
  opacity: 1
  margin: 5px

mat-expansion-panel-header
  @extend .primary-color
  font-weight: bold !important
  height: 40px !important
  padding-left: 15px !important
  width: 100%  !important


mat-action-row
  padding: 5px !important
  text-align: center


.removeBackground
  filter: blur(15px)
  cursor: pointer !important
  opacity: 0.7 !important
  pointer-events: none !important


.mini-icon-button
  font-size: 20px
  cursor: pointer
  margin: 2px
  display: inline


.dark_separator
  border: 1px solid black
  opacity: 0.3
  width: 98%
  margin-left: 0
  margin-top: 10px
  margin-bottom: 10px


.search_zone
  max-width: 650px
  width: 70%
  padding-right: 10px
  margin-right: 10px

.search_zone::part(outline)
  border: none !important


.main-message
  @extend .mat-headline-6 !optional


.appli-title
    @extend .mat-subtitle-2 !optional
    font-size: calc(13px + 0.8vw)
    font-variant-caps: all-small-caps
    margin: 8px

.flip-card
  max-width: 400px
  width: 100%
  text-align: left
  position: relative
  display: inline-block
  height: 150px
  perspective: 1000px


.flip-card-inner
  position: relative
  width: 100%
  height: 100%
  transition: transform 0.6s
  transform-style: preserve-3d


.flip-card-inner-rotate
  position: relative
  text-align: center
  transition: transform 0.6s
  transform-style: preserve-3d
  transform: rotateY(180deg)


.flip-card-front, .flip-card-back
  @extend .grey800
  position: absolute
  border-radius: 16px 16px
  padding: 10%
  width: 80%
  height: 90px
  -webkit-backface-visibility: hidden
  backface-visibility: hidden
  overflow: hidden
  transform: rotateX(0deg)

//https://stackoverflow.com/questions/9604982/backface-visibility-not-working-properly-in-firefox-works-in-safari


.flip-card-back
  position: relative
  transform: rotateY(180deg)


.label
  @extend .color-grey600


th
  @extend .color-grey600

  font-weight: normal
  min-width: 100px


.app-prompt
  padding: 20px
  margin: 0
  position: fixed
  display: block
  left: 20vw
  top: 20vh
  width: 60vw
  height: fit-content
  max-width: 600px
  max-height: 400px
  @extend .grey800


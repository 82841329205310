@use '@angular/material' as mat;
@include mat.core(); //<-- cette ligne ne doit surtout pas être supprimé

html {font-size: 1.1vw;}
@media(max-width: 1500px) { html {font-size: 1.6vw;} }
@media(max-width: 1000px) { html {font-size: 1.9vw;} }
@media(max-width: 600px) { html {font-size: 2.8vw;} }           //Agrandir l'ensemble des polices pour les mobiles

$dataculture-typography: mat.define-typography-config(
        $headline-1: mat.define-typography-level(3.8rem,3.8rem, $font-weight: 600, $font-family: Roboto),
        $headline-2: mat.define-typography-level(2.8rem,2.8rem, $font-weight: 600, $font-family: Roboto),
        $headline-3: mat.define-typography-level(2.2rem,2.2rem, $font-weight: 500, $font-family: Roboto),
        $headline-4: mat.define-typography-level(2.0rem,2.0rem, $font-weight: 500, $font-family: Roboto),
        $headline-5: mat.define-typography-level(1.8rem,1.8rem, $font-weight: 500, $font-family: Roboto),
        $headline-6: mat.define-typography-level(1.2rem,1.2rem, $font-weight: 300, $font-family: Roboto),
        $subtitle-1: mat.define-typography-level(16px, 16px, 400, Roboto),
        $subtitle-2: mat.define-typography-level(15px, 15px, 400, Roboto),
        $body-1: mat.define-typography-level(14px, 14px, 400, Roboto),
        $body-2: mat.define-typography-level(12px, 12px, 300, Roboto),
        $button: mat.define-typography-level(12px, 8px, 400, Roboto),
        $caption: mat.define-typography-level(10px, 10px, 300, Roboto),
        $overline: mat.define-typography-level(10px, 10px, 300, Roboto)
);



//voir https://material.angular.io/guide/theming#palettes
$dataculture-red-palette :(
        50: #feecee,
        100: #fcd0d3,
        200: #ea9f9d,
        300: #df7b78,
        400: #e85f56,
        500: #ec533e,
        600: #de4a3d,
        700: #cc4137,
        800: #bf3b31,
        900: #af3326,
        contrast: (
                50: rgba(black,0.87),
                100: rgba(black,0.87),
                200: rgba(black,0.87),
                300: rgba(black,0.87),
                400: rgba(black,0.87),
                500: white,
                600: white,
                700: white,
                800: white,
                900: white
        )
);

$dataculture-grey-palette :(
        50: #f8f9fc,
        100: #f1f2f5,
        200: #e8e9ec,
        300: #d8d9dc,
        400: #b4b6b8,
        500: #949698,
        600: #6c6d70,
        700: #3a3b3e,
        800: #232429,
        900: #1a1b1d,
        contrast: (
                50: rgba(black,0.87),
                100: rgba(black,0.87),
                200: rgba(black,0.87),
                300: rgba(black,0.87),
                400: rgba(black,0.87),
                500: rgba(black,0.87),
                600: rgba(black,0.87),
                700: white,
                800: white,
                900: white
        )
);


//Définition des couleurs principales utilisé dans Data Culture

//Background page : #1A1B1D
//Background menu et blocs : #232429
//
//Pictos gris : #72737C
//
//Rouge : #CC4137
//Orange : #E4983C
//Vert : #81C784
//Bleu : #5471D2
//
//Texte blanc : #ffffff
//Texte gris : ##8B8C93



$dataculture-primary-color: mat.define-palette($dataculture-red-palette, 700);
$dataculture-secondary-color: mat.define-palette($dataculture-grey-palette, 200);
$dataculture-accent-color:mat.define-palette($dataculture-red-palette, 700);
$dataculture-warn-color: mat.define-palette($dataculture-red-palette, 900);
$dataculture-green: #81C784;
$dataculture-yellow: #eee117;
$dataculture-blue: #5471D2;
$dataculture-red: #CC4137;
$dataculture-orange: #E4983C;
$dataculture-semigrey: mat.define-palette($dataculture-grey-palette,500);
$dataculture-background: mat.get-color-from-palette($dataculture-grey-palette,900);
$dataculture-foreground: mat.define-palette($dataculture-grey-palette,50);


$dataculture-theme:mat.define-dark-theme((
        color: (
                primary: $dataculture-primary-color,
                secondary: $dataculture-secondary-color,
                accent: $dataculture-accent-color,
                warn:  $dataculture-warn-color,
        ),
        background:$dataculture-background,
        typography: $dataculture-typography,
));

@include mat.all-component-themes($dataculture-theme);
@include mat.all-component-typographies($dataculture-typography);               //Application de la typographie

.student-name {
        @extend .mat-body-2 !optional;
}

.student-promo {
        @extend .mat-body-1 !optional;
}

.student-department {
        @extend .mat-body-1 !optional;
}

.expanded-plus {
        @extend .mat-subtitle-2 !optional;
        color: $dataculture-orange
}

.grey600 {
        background-color: mat.get-color-from-palette($dataculture-grey-palette,600)
}

.grey800 {
        background-color: mat.get-color-from-palette($dataculture-grey-palette,800) !important;
}

.grey700 {
        background-color: mat.get-color-from-palette($dataculture-grey-palette,700)
}

.grey900 {
        background-color: mat.get-color-from-palette($dataculture-grey-palette,900) !important;
}

.color-grey600 {
        color: mat.get-color-from-palette($dataculture-grey-palette,600);
}


.color-red500 {
        color: mat.get-color-from-palette($dataculture-red-palette,500);
}

.dataculture-background-color {
        background-color: $dataculture-background
}

.film-title {
        @include mat.typography-level($dataculture-typography,"body-1");
}

.blur {
        filter: blur(0.2px);
}

.fi-color {
        color: $dataculture-green;
}

.fa-color {
        color: $dataculture-yellow;
}

.material-icon.fi-color {
        color: $dataculture-green;
}

.material-icon.fa-color {
        color: $dataculture-yellow;
}

.material-icon.fc-color {
        color: $dataculture-blue
}

.fc-color {
        color: $dataculture-blue
}

.primary-color {
        @extend .color-red500
}

.mat-caption {
  font-size: medium !important;
}

.expansion-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  align-items: center;
  justify-content: space-around;
}

.expansion-content-left {
  @extend .expansion-content;
  justify-content: flex-start;
}
